import React from 'react';
import Link from 'next/link';

import { scale } from '@scripts/gds';
import { transformImageUrl } from '@scripts/helpers';

import Carousel from '@controls/Carousel';
import Picture from '@controls/future/Picture';

import { RightBannerProps } from '@views/index/components/types';

const RightBanner = ({ buttonStyles, rightBanner }: RightBannerProps) => (
    <div
        css={{
            height: scale(40),
            width: scale(38),
            position: 'relative',
        }}
    >
        <Carousel pagination spaceBetween={scale(3)} slidesPerView={1} css={{ width: '100%' }} lazy>
            {rightBanner.map((item, index) => (
                <Carousel.Item key={item.desktop_image}>
                    {item?.url && item?.button?.text && (
                        <Link legacyBehavior href={item.url} passHref prefetch={false}>
                            <a
                                aria-label={item.button.text}
                                css={{
                                    ...buttonStyles,
                                    left: scale(3),
                                    padding: `${scale(1, false, 14)}px ${scale(3)}px !important`,
                                }}
                            >
                                {item.button.text}
                            </a>
                        </Link>
                    )}
                    <Link legacyBehavior href={item.url || ''} passHref prefetch={false}>
                        <Picture
                            sources={[
                                {
                                    media: '(max-width: 567px)',
                                    image: transformImageUrl(item.mobile_image || '', scale(38), scale(40)),
                                },
                                {
                                    media: '(max-width: 767px)',
                                    image: transformImageUrl(item.tablet_image || '', scale(38), scale(40)),
                                },
                                {
                                    media: '(min-width: 768px)',
                                    image: transformImageUrl(item.desktop_image || '', scale(38), scale(40)),
                                },
                            ]}
                            loading={index === 0 ? 'eager' : 'lazy'}
                            alt={item.name}
                            width="auto"
                            height="auto"
                            placeholder="blur"
                            style={{
                                cursor: item.url ? 'pointer' : 'default',
                                objectFit: 'fill',
                                height: scale(40),
                                width: '100%',
                                borderRadius: scale(1),
                            }}
                        />
                    </Link>
                </Carousel.Item>
            ))}
        </Carousel>
    </div>
);

export default RightBanner;
